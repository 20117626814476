export default function coordinatesFormater(coord: string) : number {
  let formatedCoordinates = 0;
  if (coord[0] === 'N' || coord[0] === 'S') {
    const letter = (coord[0] === 'N') ? '' : '-';
    const seconds = Number(coord.slice(-4)) / 6000;
    const minutes = (Number(coord.slice(-6, -4)) + seconds) / 60;
    const degrees = (Number(coord.slice(1, 3)) + minutes).toFixed(2);
    const result = `${letter}${degrees}`;
    formatedCoordinates = Number(result);
  } else {
    const letter = (coord[0] === 'E') ? '' : '-';
    const seconds = Number(coord.slice(-4)) / 6000;
    const minutes = (Number(coord.slice(-6, -4)) + seconds) / 60;
    const degrees = (Number(coord.slice(1, 4)) + minutes).toFixed(2);
    const result = `${letter}${degrees}`;
    formatedCoordinates = Number(result);
  }

  return formatedCoordinates;
}
