import { IAircraft } from '@/interfaces/aircraft';
import { IAirportResponse } from '@/interfaces/IAirportResponse';
import { IPath } from '@/interfaces/IPath';

class ApiClient {
  socket!: WebSocket;

  status: string | undefined;

  init(callback: (arg0: string) => void) {
    this.socket = (process.env.NODE_ENV === 'production')
      ? new WebSocket('wss://horizon-flightplanner.ru/backend/')
      : new WebSocket('ws://localhost:8082/backend/');
    this.socket.onopen = () => {
      callback('Online');
    };
    this.socket.onclose = () => {
      callback('Offline');
    };
    this.socket.onerror = (event) => {
      callback(`Some error: ${event.type}`);
    };
  }

  requestAircraftsList(callback: (arg0: IAircraft[]) => void) {
    const request = {
      type: 'requestAircrafts',
    };
    this.socket.send(JSON.stringify(request));
    this.socket.onmessage = (message) => {
      const response = JSON.parse(message.data);
      if (response.length > 0) {
        const result = response;
        callback(result);
      }
    };
  }

  sendAirport(airportCode: string, airportType: string, callback: (arg0: IAirportResponse, arg1: string) => void) {
    if (airportCode.length < 3) return;
    const requestUpper = airportCode.toUpperCase();
    const searchRequest = {
      type: 'search',
      data: requestUpper,
    };
    this.socket.send(JSON.stringify(searchRequest));
    this.socket.onmessage = (message) => {
      callback(JSON.parse(message.data), airportType);
    };
  }

  // sendAircraft(msn: string, callback: (arg0: string | number) => void) {
  // 	const searchRequest = {
  // 		type: 'search',
  // 		data: msn,
  // 	};
  // 	this.socket.send(JSON.stringify(searchRequest));
  // 	this.socket.onmessage = (message) => {
  // 		const response = JSON.parse(message.data);
  // 		if (response.length > 0) {
  // 			const result = { ...response[0] };
  // 			callback(result);
  // 		}
  // 	};
  // }

  calculateFlight(request: object, callback: (data: object) => void) {
    const searchRequest = {
      type: 'calculateFlight',
      data: request,
    };
    this.socket.send(JSON.stringify(searchRequest));
    this.socket.onmessage = (message) => {
      const response = JSON.parse(message.data);
      if (Object.entries(response).length > 0) {
        callback(response);
      }
    };
  }

  pathFinder(departure: string, arrival: string, callback: (path: IPath) => void) {
    const searchRequest = {
      type: 'pathFinder',
      data: [departure, arrival],
    };
    this.socket.send(JSON.stringify(searchRequest));
    this.socket.onmessage = (message) => {
      const response = JSON.parse(message.data) as IPath;
      callback(response);
    };
  }
}

let instance = null;
export default instance || (instance = new ApiClient());
