import { IAirportToMap } from '@/interfaces/airportToMap';
import { defineStore } from 'pinia';
import { IGeoObject } from 'yandex-maps';

export default defineStore('yaMapStore', {
  state: () => ({
    airports: [] as IAirportToMap[],
    geoAirports: [] as IGeoObject[],
  }),
});
