export default class CFpl {
  msgCode: string;

  flightNumber: string;

  flightType: string;

  aircraftType: string;

  aircraftEquipment: string;

  field9: string;

  departureAirport: string;

  departureTime: string;

  departureData: string;

  route: string;

  arrivalAirport: string;

  arrivalData: string;

  field18: string;

  dof: string;

  firOnly: string[];

  constructor(fpl: string) {
    const data1 = fpl.replace(/\n/g, ' ');
    const data = data1.replace(/ {2}/g, ' ');
    const fplArr = data.split('-');
    fplArr.forEach((el) => el.toUpperCase());
    [this.msgCode, this.flightNumber, this.flightType, this.aircraftType, this.aircraftEquipment] = fplArr;
    // this.flightNumber = fplArr[1];
    this.flightType = `8/${fplArr[2]}`;
    // this.aircraftType = fplArr[3];
    // this.aircraftEquipment = fplArr[4];
    this.field9 = `9/${this.aircraftType}-${this.aircraftEquipment}`;
    this.departureAirport = fplArr[5].slice(0, 4);
    this.departureTime = fplArr[5].slice(4, 8);
    this.departureData = `13/${fplArr[5].trim()}`;
    this.route = `15/${fplArr[6].trim()}`;
    this.arrivalAirport = fplArr[7].slice(0, 4);
    this.arrivalData = `16/${fplArr[7].trim()}`;
    this.field18 = `18/${data.slice(data.indexOf('PBN'))}`;
    this.dof = Array.from(this.field18).splice(this.field18.indexOf('DOF'), 11).join('');
    const firsWithTime = Array.from(
      this.field18.slice(
        this.field18.indexOf('EET'),
        Math.min(this.field18.indexOf('CODE'), this.field18.indexOf('OPR')),
      ),
    )
      .splice(4)
      .join('')
      .trim()
      .split(' ');
    this.firOnly = [];
    firsWithTime.forEach((el) => this.firOnly.push(el.slice(0, 4)));
  }
}
